import useUserRoles from "@/uses/useUserRoles";
import { computed, defineComponent, ref } from "vue";
import { useRoute, useRouter, onBeforeRouteUpdate } from "vue-router";

import {
  AssessmentItem,
  AssessmentType,
  CodeStatus,
  SubjectItem,
  SubjectProfile,
} from "./../../../api/interfaces/SubjectsInterfaces";
import { useStore } from "@/store/store";
import { MetadataMutations } from "@/store/metadataModule";
import {
  getLastAssessment,
  revokeAssesment,
  startNewAssesment,
  viewSubjectProfile,
} from "@/api/SubjectsApi";

import CancelIcon from "@/assets/icons/Cancel.vue";
import PlayCircleIcon from "@/assets/icons/PlayCircle.vue";
import InfoIcon from "@/assets/icons/Info.vue";
import { showError, showErrorOptional } from "@/utils/notificationUtils";

export default defineComponent({
  name: "TabsParentSite",
  props: {},
  components: { PlayCircleIcon, InfoIcon, CancelIcon },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const subjectId = route.params.id as string;
    const siteId = route.params.siteId as string;

    const subject = ref<SubjectProfile>();
    const assessment = ref<AssessmentItem>();

    viewSubjectProfile(subjectId, siteId)
      .then(async (subjectProfile) => {
        store.commit(
          MetadataMutations.SET_BREAD_CRUMBS_NAME,
          subjectProfile.subject_id
        );

        subject.value = subjectProfile;
      })
      .catch((ex) => {
        showErrorOptional("Subject is not found", ex);
      });

    const updateLastAssessment = () =>
      getLastAssessment(subjectId, siteId)
        .then(async (lastAssessment) => {
          if (lastAssessment) {
            assessment.value = {
              assessment_type: AssessmentType.HiPAL,
              invitation_code_value: lastAssessment.code,
              invitation_code_status: lastAssessment.status,
            };
          }
        })
        .catch((ex) => {
          assessment.value = undefined;
        });
    updateLastAssessment();

    // const parts = route.fullPath.split("/").filter((p) => p);

    // const getLastAssessment = (item: SubjectProfile) =>
    //   item.last_assessments[0] || null;

    // onBeforeRouteUpdate((to, from, next) => {
    //   console.log("to, from, next: ", to, from, next);
    // });

    const links = ref([
      { name: "View Subject", url: " ", label: "General information" },
      { name: "View HiPAL Pro", url: "hipal-pro", label: "HiPAL Pro" },
    ]);

    return {
      subject,
      assessment,
      CodeStatus,
      links,
      ...useUserRoles(store),
      subjectCodeStatus: computed(
        // () => "Entered" //getLastAssessment(subject.value)?.code_status
        () => assessment.value?.invitation_code_status
      ),
      showStartNewAssessment: () => true,
      activeTab: computed(() => {
        const link = links.value.find((l) => l.name === route.name);

        return link?.url || " ";
      }),
      startNewAssessment: async () => {
        if (subject.value) {
          const result = await startNewAssesment(
            subject.value.id as string,
            subject.value.site_id
          );

          if (assessment.value) {
            assessment.value.invitation_code_status = result.status;
            assessment.value.invitation_code_value = result.code;
          } else {
            updateLastAssessment();
          }
        }
      },
      revoke: async () => {
        if (subject.value) {
          await revokeAssesment(
            subject.value.id as string,
            subject.value.site_id
          );

          if (assessment.value) {
            assessment.value.invitation_code_status = CodeStatus.Revoked;
          } else {
            updateLastAssessment();
          }
        }
      },
      changeTab(tabName: any) {
        router.push(
          `/subjects/view-subject/${siteId}/${subjectId}/${tabName.props.name.trim()}`
        );
      },
    };
  },
});
